import React, { useContext, useState } from "react"
import styles from "./cards-search.module.scss"
import CasinoCard from "../casino-card/casino-card"
import {
  Grid,
  Slider,
  Checkbox,
  FormControlLabel,
  FormControl,
  NativeSelect,
  Button,
  Dialog,
  IconButton,
} from "@material-ui/core"
import SearchBox from "../search-box/search-box"
import { makeStyles } from "@material-ui/core/styles"
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked"
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked"
import CloseIcon from '@material-ui/icons/Close';
import FilterListIcon from '@material-ui/icons/FilterList';
import ThemeContext from "../../context/ThemeContext"
import h from "../../services/GenericHelpers"
import t from "../../services/GetTranslationValue"
import NullIf from "../../services/NullIf"
import CasinoBonusHelpers from "../../services/casino-bonus-helpers"
import RadioGroup from "@material-ui/core/RadioGroup"
import Radio from "@material-ui/core/Radio"
import {
  HasBingo,
  HasEWallet,
  HasGamification,
  HasLotto,
  HasNoAccount,
  HasNumberOfGames, HasOneHundredBonuses,
  HasOverTwoHundredBonuses,
  HasSportsBook,
  HasTwoHundredBonuses,
  HasVipProgram,
  HasWageringRequirement,
  IsCashable,
  IsExclusive,
  IsMobileFriendly,
  IsNonSticky,
  IsTaxExempt,
  labelWithNumber,
} from "../search-box/search-helpers"

const useStyles = makeStyles({
  root: {},
})

const CardsSearch = ({ locale, forceSportsbook, forceTopBonus, forceSpecificBonusType, showBonusCode, casinos }) => {

  const site = useContext(ThemeContext);
  let { bonuses } = site
  if (casinos)
    bonuses = casinos;

  let bonusType = [
    {
      name: 'Tervetuliaistarjous',
      id: '5RxK3fOOaj8nmhbsLyckKE',
    },
    {
      name: 'Ilman Talletusta',
      id: "6kr7CAyGwLJohmHX7MCh8b",
    },
    {
      name: 'Ilmaista pelirahaa',
      id: "Q3bWwjbIEWuZBDLnkB7cp",
    },
    {
      name: 'Cashback',
      id: "1rBGIDOawVhZrUweOt8zHx",
    },
    {
      name: 'Vedonlyöntibonus',
      id: "2KwEEnrylzgdzSGeiOCnaX",
    }
  ];

  const bonusTypeEn = [
    {
      name: 'Welcome Bonus',
      id: "5RxK3fOOaj8nmhbsLyckKE",
    },
    {
      name: 'Freespins No Deposit',
      id: "6kr7CAyGwLJohmHX7MCh8b",
    },
    {
      name: 'No Deposit Bonus',
      id: "Q3bWwjbIEWuZBDLnkB7cp",
    },
    {
      name: 'Cashback',
      id: "1rBGIDOawVhZrUweOt8zHx",
    },
    {
      name: 'Sportsbook',
      id: "2KwEEnrylzgdzSGeiOCnaX",
    }
  ]

  if (locale !== 'fi')
    bonusType = bonusTypeEn;

  const isNonSticky = x => {
    const bonus = CasinoBonusHelpers.GetFirstDepositBonus(x)
    return bonus && NullIf.isNotNullOrEmpty(bonus.bonusValue)
  }

  const applyFilter = items => {
    let result = items
      .filter(x => ranking.casinos.slice(0, 5).some(y => y.name === x.name)
                    || h.casinoAverageRating(x) > rating)
      .filter(x => x.name.toLowerCase().includes(search.toLowerCase()))
      .filter(x => !taxExempt || IsTaxExempt(x))
      .filter(x => !exclusive || IsExclusive(x) )
      .filter(x => !cashable ||IsCashable(x))
      .filter(x => !noAccount || HasNoAccount(x))
      .filter(x => !lotto || HasLotto(x))
      .filter(x => !eWallet || HasEWallet(x))
      .filter(x => !gamification || HasGamification(x))
      .filter(x => !bingo || HasBingo(x))
      .filter(x => !mobileFriendly || IsMobileFriendly(x))
      .filter(x => !vipProgram || HasVipProgram(x))
      .filter(x => !sportsBook || HasSportsBook(x))
      .filter(x => !nonSticky || IsNonSticky(x))
      .filter(x => HasNumberOfGames(x, gameNumber))
      .filter(x => !bonusAmount || getTopBonus(x, bonusAmount))
      .filter(x => HasWageringRequirement(x, wageringType, wageringAmount))
      .filter(x => !bonusTypeState || CasinoBonusHelpers.GetBonusFromCasinoByTypeId(x, bonusTypeState))

      if (bonusAmount)
        result = result.slice().sort((a,b) => {
          let leftBonus = CasinoBonusHelpers.GetFirstDepositBonus(a);
          let rightBonus = CasinoBonusHelpers.GetFirstDepositBonus(b);
          if (!leftBonus || !rightBonus)
            return 0;
          if (rightBonus.bonusValue  > leftBonus.bonusValue)
            return 1;
          if (rightBonus.bonusValue < leftBonus.bonusValue)
            return -1;
          return 0;
        })

    return result;
  }

  const getTopBonus = (x, bonusAmount) => {

    if (bonusAmount === "100")
        return CasinoBonusHelpers.GetTopFirstDepositBonus(x) && CasinoBonusHelpers.GetTopFirstDepositBonus(x).bonusValue === 100;

    if (bonusAmount === "200")
      return CasinoBonusHelpers.GetTopFirstDepositBonus(x) && CasinoBonusHelpers.GetTopFirstDepositBonus(x).bonusValue <= 200 && CasinoBonusHelpers.GetTopFirstDepositBonus(x).bonusValue > 100;

    if (bonusAmount === "max")
      return CasinoBonusHelpers.GetTopFirstDepositBonus(x) && CasinoBonusHelpers.GetTopFirstDepositBonus(x).bonusValue >= 200;

    return false
  }

  const distinct = (value, index, self) => {
    return self.indexOf(value) === index
  }

  const getAllWageringRequirementTypesUsed = bonuses => {
    bonuses = bonuses
      .filter(x => NullIf.isNotNullOrEmpty(x))
      .map(x => x.bonuses)

    bonuses = [].concat.apply([], bonuses)

    bonuses = bonuses
      .filter(x => NullIf.isNotNullOrEmpty(x))
      .map(x => x.wageringRequirementType)
      .filter(x => NullIf.isNotNullOrEmpty(x))
      .filter(distinct)
    return bonuses
  }

  const getBonuses = (items) => {
    return applyFilter(items)
  }
  const getFilteredBonuses = (items, translations, locale) => {
    let result = items;

    result = result.slice(0, showItems)

    return result.map(x => (
      <CasinoCard forceSpecificId={bonusTypeState} showBonusCode={showBonusCode} forceTopBonus={forceTopBonus || bonusAmount} casino={x} translations={translations} locale={locale} />
    ))
  }

  const openFilters = () => {
    setFiltersVisible(true);
  };

  const closeFilters = () => {
    setFiltersVisible(false);
  };

  const clearState = () => {
    setRating(0)
    setBingo(false)
    setLotto(false)
    setNoAccount(false)
    setNonSticky(false)
    setExclusive(false)
    setSportsBook(false)
    setSearch("")
    setCashable(false)
    setEWallet(false)
    setGameNumber(0)
    setGamification(false)
    setVipProgram(false)
    setMobileFriendly(false)
    setTaxExempt(false)
    setWageringType('')
    setWageringAmount(50)
    setBonusTypeState('')
    setBonusAmount('')
  }

  const getCasinosFoundText = (count, translations) => {
    if (count == 0)
      return t.get(translations, 'no-casinos-found');
    if (count > 1)
      return t.get(translations, 'casinos-found');

    return t.get(translations, 'casino-found');
  }

  let { translations, ranking } = site
  // setup filter states.
  const [filtersVisible, setFiltersVisible] = useState(false)
  const [rating, setRating] = useState(3)
  const [search, setSearch] = useState("")
  const [taxExempt, setTaxExempt] = useState(false)
  const [exclusive, setExclusive] = useState(false)
  const [mobileFriendly, setMobileFriendly] = useState(false)
  const [eWallet, setEWallet] = useState(false)
  const [cashable, setCashable] = useState(false)
  const [noAccount, setNoAccount] = useState(false)
  const [lotto, setLotto] = useState(false)
  const [bingo, setBingo] = useState(false)
  const [gamification, setGamification] = useState(false)
  const [vipProgram, setVipProgram] = useState(false)
  const [sportsBook, setSportsBook] = useState(false)
  const [nonSticky, setNonSticky] = useState(false)
  const [gameNumber, setGameNumber] = useState(300)
  const [showItems, setShowItems] = useState(10)
  const [wageringType, setWageringType] = useState('x(d+b)');
  const [wageringAmount, setWageringAmount] = useState(50);
  const [bonusAmount, setBonusAmount] = useState();
  const [bonusTypeState, setBonusTypeState] = useState(forceSpecificBonusType ? (bonusType.find(x => x.name.toLowerCase() === forceSpecificBonusType.toLowerCase()) ? bonusType.find(x => x.name.toLowerCase() === forceSpecificBonusType.toLowerCase()).id : '') : '');

  if (forceSportsbook)
    setSportsBook(true)

  let filteredBonuses = applyFilter(bonuses)
  let displayBonuses = getFilteredBonuses(filteredBonuses, translations, locale);

  return (
    <Grid id={`card-search-filters`} container spacing={0} className={`${styles.cardsSearch}`}>
      <Grid container spacing={0} className={`gamble-hidden-md-down ${styles.filters}`}>
        <Grid item xs={12} md={6} lg={4} className={`${styles.part1}`}>
          <p>{t.get(translations, 'select-filters')}</p>
          <div>
            <FormControl component="fieldset">
              <FormControlLabel
                checked={exclusive}
                onChange={(event, val) => setExclusive(val)}
                control={
                  <Checkbox
                    icon={<RadioButtonUncheckedIcon />}
                    checkedIcon={<RadioButtonCheckedIcon />}
                    value="checkedH"
                    color="primary"
                  />
                }
                label={labelWithNumber(t.get(translations, "is-exclusive"), filteredBonuses.filter(x => IsExclusive(x)).length)}
                className={`gamble-radio`}
              />
              <FormControlLabel
                checked={mobileFriendly}
                onChange={(event, val) => setMobileFriendly(val)}
                control={
                  <Checkbox
                    icon={<RadioButtonUncheckedIcon />}
                    checkedIcon={<RadioButtonCheckedIcon />}
                    value="checkedH"
                    color="primary"
                  />
                }
                label={labelWithNumber(t.get(translations, "mobile-friendly"), filteredBonuses.filter(x => IsMobileFriendly(x)).length)}
                className={`gamble-radio`}
              />
              <FormControlLabel
                checked={noAccount}
                onChange={(event, val) => setNoAccount(val)}
                control={
                  <Checkbox
                    icon={<RadioButtonUncheckedIcon />}
                    checkedIcon={<RadioButtonCheckedIcon />}
                    value="checkedH"
                    color="primary"
                  />
                }
                label={labelWithNumber(t.get(translations, "has-no-account"), filteredBonuses.filter(x => HasNoAccount(x)).length)}
                className={`gamble-radio`}
              />
              <FormControlLabel
                checked={lotto}
                onChange={(event, val) => setLotto(val)}
                control={
                  <Checkbox
                    icon={<RadioButtonUncheckedIcon />}
                    checkedIcon={<RadioButtonCheckedIcon />}
                    value="checkedH"
                    color="primary"
                  />
                }
                label={labelWithNumber(t.get(translations, "has-lotto"), filteredBonuses.filter(x => HasLotto(x)).length)}
                className={`gamble-radio`}
              />
              <FormControlLabel
                checked={eWallet}
                onChange={(event, val) => setEWallet(val)}
                control={
                  <Checkbox
                    icon={<RadioButtonUncheckedIcon />}
                    checkedIcon={<RadioButtonCheckedIcon />}
                    value="checkedH"
                    color="primary"
                  />
                }
                label={labelWithNumber(t.get(translations, "e-wallet"), filteredBonuses.filter(x => HasEWallet(x)).length)}
                className={`gamble-radio`}
              />
              <FormControlLabel
                checked={bingo}
                onChange={(event, val) => setBingo(val)}
                control={
                  <Checkbox
                    icon={<RadioButtonUncheckedIcon />}
                    checkedIcon={<RadioButtonCheckedIcon />}
                    value="checkedH"
                    color="primary"
                  />
                }
                label={labelWithNumber(t.get(translations, "has-bingo"), filteredBonuses.filter(x => HasBingo(x)).length)}
                className={`gamble-radio`}
              />
              <FormControlLabel
                checked={gamification}
                onChange={(event, val) => setGamification(val)}
                control={
                  <Checkbox
                    icon={<RadioButtonUncheckedIcon />}
                    checkedIcon={<RadioButtonCheckedIcon />}
                    value="checkedH"
                    color="primary"
                  />
                }
                label={labelWithNumber(t.get(translations, "has-gamification"), filteredBonuses.filter(x => HasGamification(x)).length)}
                className={`gamble-radio`}
              />
              {locale ==='fi' &&
              <FormControlLabel
                checked={taxExempt}
                onChange={(event, val) => setTaxExempt(val)}
                control={
                  <Checkbox
                    icon={<RadioButtonUncheckedIcon />}
                    checkedIcon={<RadioButtonCheckedIcon />}
                    value="checkedH"
                    color="primary"
                  />
                }
                label={labelWithNumber(t.get(translations, "Tax Exempt"), filteredBonuses.filter(x => IsTaxExempt(x)).length)}
                className={`gamble-radio`}
              />
              }
            </FormControl>
          </div>
        </Grid>
        <Grid item xs={12} md={6} lg={3} className={`${styles.part2}`}>
          <div className={`${styles.top}`}>
            <p>{t.get(translations, 'bonus-type')}</p>
            <NativeSelect
              className={`gamble-select ${styles.select}`}
              name="bonus type"
              value={bonusTypeState}
              onChange={(event) => setBonusTypeState(event.target.value)}
              inputProps={{ "aria-label": "bonus type" }}
            >
              {bonusType
                .map(x => (
                  <option value={x.id}>{x.name}</option>
                ))}
            </NativeSelect>
          </div>
          <div className={`${styles.bottom}`}>
            <p>{labelWithNumber(t.get(translations, 'max-wager'), filteredBonuses.filter(x => HasWageringRequirement(x, wageringType, wageringAmount)).length)}</p>
            <Slider
              className={`gamble-slider ${styles.slider}`}
              color="primary"
              defaultValue={50}
              step={1}
              marks={[
                {
                  value: 0,
                  label: "0",
                },
                {
                  value: 100,
                  label: "100",
                },
              ]}
              min={0}
              max={100}
              value={wageringAmount}
              onChange={(e, val) => setWageringAmount(val)}
              valueLabelDisplay="auto"
            />
            <NativeSelect
              className={`gamble-select ${styles.select}`}
              name="bonus type"
              value={wageringType}
              onChange={(event) => setWageringType(event.target.value)}
              inputProps={{ "aria-label": "bonus type" }}
            >
              {getAllWageringRequirementTypesUsed(bonuses).map(x => (
                <option value={x}>{t.get(translations, x)}</option>
              ))}
            </NativeSelect>
          </div>
        </Grid>
        <Grid item xs={12} md={6} lg={3} className={`${styles.part3}`}>
          <p>{labelWithNumber(t.get(translations, "number-of-slots"), filteredBonuses.filter(x => HasNumberOfGames(x, gameNumber)).length)}</p>
          <Slider
            className={`gamble-slider ${styles.slider}`}
            color="primary"
            defaultValue={500}
            step={1}
            marks={[
              {
                value: 0,
                label: "0",
              },
              {
                value: 500,
                label: "500",
              },
              {
                value: 1000,
                label: "1000",
              },
              {
                value: 1500,
                label: "1500",
              },
              {
                value: 2000,
                label: "2000",
              },
            ]}
            min={0}
            max={2000}
            value={gameNumber}
            onChange={(e, val) => setGameNumber(val)}
            valueLabelDisplay="auto"
          />
          <p>{labelWithNumber(t.get(translations, 'search-rating'), filteredBonuses.length)}</p>
          <Slider
            className={`gamble-slider ${styles.slider}`}
            color="primary"
            defaultValue={1}
            step={0.1}
            marks={[
              {
                value: 0,
                label: "0",
              },
              {
                value: 5,
                label: "5",
              },
            ]}
            min={0}
            max={5}
            value={rating}
            onChange={(e, val) => setRating(val)}
            valueLabelDisplay="auto"
          />
        </Grid>
        <Grid item xs={12} md={6} lg={2} className={`${styles.part4}`}>
          <p>{t.get(translations, 'bonus-type')}</p>
          <FormControl component="fieldset">
            <RadioGroup>
            <FormControlLabel
              checked={bonusAmount === "100"}
              onChange={(event) => setBonusAmount(event.target.value)}
              control={
                <Radio
                  icon={<RadioButtonUncheckedIcon />}
                  checkedIcon={<RadioButtonCheckedIcon />}
                  value="100"
                  color="primary"
                />
              }
              label={labelWithNumber('100%', filteredBonuses.filter(x => HasOneHundredBonuses(x)).length)}
              className={`gamble-radio`}
            />
            <FormControlLabel
              checked={bonusAmount === "200"}
              onChange={(event) => setBonusAmount(event.target.value)}
              control={
                <Radio
                  icon={<RadioButtonUncheckedIcon />}
                  checkedIcon={<RadioButtonCheckedIcon />}
                  value="200"
                  color="primary"
                />
              }
              label={labelWithNumber('200%', filteredBonuses.filter(x => HasTwoHundredBonuses(x)).length)}
              className={`gamble-radio`}
            />
            <FormControlLabel
              checked={bonusAmount === "max"}
              onChange={(event) => setBonusAmount(event.target.value)}
              control={
                <Radio
                  icon={<RadioButtonUncheckedIcon />}
                  checkedIcon={<RadioButtonCheckedIcon />}
                  value="max"
                  color="primary"
                />
              }
              label={labelWithNumber('200% +', filteredBonuses.filter(x => HasOverTwoHundredBonuses(x)).length)}
              className={`gamble-radio`}
            />
            </RadioGroup>
          </FormControl>
          <p>{t.get(translations, "search")}</p>
          <SearchBox search={search} setSearch={setSearch} />
        </Grid>
        <Grid item xs={12} className={`${styles.clearButton}`}>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            onClick={() => clearState()}
          >
            {t.get(translations, 'clear-filters')}
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12} className={`${styles.cards}`}>
        <span id={'cards-start'}></span>
        {displayBonuses}
      </Grid>
      <Grid item xs={12} className={`gamble-hidden-md-up ${styles.mobileFilters}`}>
        <Grid container spacing={0} className={`${styles.filters}`}>
          <Button
            color="primary"
            className={`${styles.filtersButton}`}
            onClick={openFilters}
          >
            <FilterListIcon />
            {t.get(translations, 'search-filter')}
          </Button>
          <Dialog fullScreen open={filtersVisible} onClose={closeFilters}>
            <div className={`${styles.filtersDialog}`}>
              <a href="#cards-start" aria-label={t.get(translations, "search-apply-filters")} style={{ textDecoration: 'none' }}>
                <IconButton edge="start" color="primary" onClick={closeFilters} aria-label="close" className={`${styles.filtersCloseButton}`}>
                  <CloseIcon />
                </IconButton>
              </a>
              <div className={`${styles.search}`}>
                <SearchBox search={search} setSearch={setSearch} />
              </div>
              <p className={`${styles.casinoResultCount}`}>{filteredBonuses.length} {getCasinosFoundText(filteredBonuses.length, translations)}</p>
              <Grid item xs={12} md={6} lg={4} className={`${styles.part1}`}>
                <p>{t.get(translations, 'select-filters')}</p>
                <div>
                  <FormControl component="fieldset">
                    <FormControlLabel
                      checked={exclusive}
                      onChange={(event, val) => setExclusive(val)}
                      control={
                        <Checkbox
                          icon={<RadioButtonUncheckedIcon />}
                          checkedIcon={<RadioButtonCheckedIcon />}
                          value="checkedH"
                          color="primary"
                        />
                      }
                      label={labelWithNumber(t.get(translations, "is-exclusive"), filteredBonuses.filter(x => IsExclusive(x)).length)}
                      className={`gamble-radio`}
                    />
                    <FormControlLabel
                      checked={mobileFriendly}
                      onChange={(event, val) => setMobileFriendly(val)}
                      control={
                        <Checkbox
                          icon={<RadioButtonUncheckedIcon />}
                          checkedIcon={<RadioButtonCheckedIcon />}
                          value="checkedH"
                          color="primary"
                        />
                      }
                      label={labelWithNumber(t.get(translations, "mobile-friendly"), filteredBonuses.filter(x => IsMobileFriendly(x)).length)}
                      className={`gamble-radio`}
                    />
                    <FormControlLabel
                      checked={noAccount}
                      onChange={(event, val) => setNoAccount(val)}
                      control={
                        <Checkbox
                          icon={<RadioButtonUncheckedIcon />}
                          checkedIcon={<RadioButtonCheckedIcon />}
                          value="checkedH"
                          color="primary"
                        />
                      }
                      label={labelWithNumber(t.get(translations, "has-no-account"), filteredBonuses.filter(x => HasNoAccount(x)).length)}
                      className={`gamble-radio`}
                    />
                    <FormControlLabel
                      checked={lotto}
                      onChange={(event, val) => setLotto(val)}
                      control={
                        <Checkbox
                          icon={<RadioButtonUncheckedIcon />}
                          checkedIcon={<RadioButtonCheckedIcon />}
                          value="checkedH"
                          color="primary"
                        />
                      }
                      label={labelWithNumber(t.get(translations, "has-lotto"), filteredBonuses.filter(x => HasLotto(x)).length)}
                      className={`gamble-radio`}
                    />
                    <FormControlLabel
                      checked={eWallet}
                      onChange={(event, val) => setEWallet(val)}
                      control={
                        <Checkbox
                          icon={<RadioButtonUncheckedIcon />}
                          checkedIcon={<RadioButtonCheckedIcon />}
                          value="checkedH"
                          color="primary"
                        />
                      }
                      label={labelWithNumber(t.get(translations, "e-wallet"), filteredBonuses.filter(x => HasEWallet(x)).length)}
                      className={`gamble-radio`}
                    />
                    <FormControlLabel
                      checked={bingo}
                      onChange={(event, val) => setBingo(val)}
                      control={
                        <Checkbox
                          icon={<RadioButtonUncheckedIcon />}
                          checkedIcon={<RadioButtonCheckedIcon />}
                          value="checkedH"
                          color="primary"
                        />
                      }
                      label={labelWithNumber(t.get(translations, "has-bingo"), filteredBonuses.filter(x => HasBingo(x)).length)}
                      className={`gamble-radio`}
                    />
                    <FormControlLabel
                      checked={gamification}
                      onChange={(event, val) => setGamification(val)}
                      control={
                        <Checkbox
                          icon={<RadioButtonUncheckedIcon />}
                          checkedIcon={<RadioButtonCheckedIcon />}
                          value="checkedH"
                          color="primary"
                        />
                      }
                      label={labelWithNumber(t.get(translations, "has-gamification"), filteredBonuses.filter(x => HasGamification(x)).length)}
                      className={`gamble-radio`}
                    />
                    <FormControlLabel
                      checked={taxExempt}
                      onChange={(event, val) => setTaxExempt(val)}
                      control={
                        <Checkbox
                          icon={<RadioButtonUncheckedIcon />}
                          checkedIcon={<RadioButtonCheckedIcon />}
                          value="checkedH"
                          color="primary"
                        />
                      }
                      label={labelWithNumber(t.get(translations, "Tax Exempt"), filteredBonuses.filter(x => IsTaxExempt(x)).length)}
                      className={`gamble-radio`}
                    />
                  </FormControl>
                </div>
              </Grid>
              <Grid item xs={12} md={6} lg={3} className={`${styles.part2}`}>
                <div className={`${styles.top}`}>
                  <p>{t.get(translations, 'bonus-type')}</p>
                  <NativeSelect
                    className={`gamble-select ${styles.select}`}
                    name="bonus type"
                    value={bonusTypeState}
                    onChange={(event) => setBonusTypeState(event.target.value)}
                    inputProps={{ "aria-label": "bonus type" }}
                  >
                    {bonusType
                      .map(x => (
                        <option value={x.id}>{x.name}</option>
                      ))}
                  </NativeSelect>
                </div>
                <div className={`${styles.bottom}`}>
                  <p>{labelWithNumber(t.get(translations, 'max-wager'), filteredBonuses.filter(x => HasWageringRequirement(x, wageringType, wageringAmount)).length)}</p>
                  <Slider
                    className={`gamble-slider ${styles.slider}`}
                    color="primary"
                    defaultValue={50}
                    step={1}
                    marks={[
                      {
                        value: 0,
                        label: "0",
                      },
                      {
                        value: 100,
                        label: "100",
                      },
                    ]}
                    min={0}
                    max={100}
                    value={wageringAmount}
                    onChange={(e, val) => setWageringAmount(val)}
                    valueLabelDisplay="auto"
                  />
                  <NativeSelect
                    className={`gamble-select ${styles.select}`}
                    name="bonus type"
                    value={wageringType}
                    onChange={(event) => setWageringType(event.target.value)}
                    inputProps={{ "aria-label": "bonus type" }}
                  >
                    {getAllWageringRequirementTypesUsed(bonuses).map(x => (
                      <option value={x}>{t.get(translations, x)}</option>
                    ))}
                  </NativeSelect>
                </div>
              </Grid>
              <Grid item xs={12} md={6} lg={3} className={`${styles.part3}`}>
                <p>{labelWithNumber(t.get(translations, "number-of-slots"), filteredBonuses.filter(x => HasNumberOfGames(x, gameNumber)).length)}</p>
                <Slider
                  className={`gamble-slider ${styles.slider}`}
                  color="primary"
                  defaultValue={500}
                  step={1}
                  marks={[
                    {
                      value: 0,
                      label: "0",
                    },
                    {
                      value: 500,
                      label: "500",
                    },
                    {
                      value: 1000,
                      label: "1000",
                    },
                    {
                      value: 1500,
                      label: "1500",
                    },
                    {
                      value: 2000,
                      label: "2000",
                    },
                  ]}
                  min={0}
                  max={2000}
                  value={gameNumber}
                  onChange={(e, val) => setGameNumber(val)}
                  valueLabelDisplay="auto"
                />
                <p>{labelWithNumber(t.get(translations, 'search-rating'), filteredBonuses.length)}</p>
                <Slider
                  className={`gamble-slider ${styles.slider}`}
                  color="primary"
                  defaultValue={1}
                  step={0.1}
                  marks={[
                    {
                      value: 0,
                      label: "0",
                    },
                    {
                      value: 5,
                      label: "5",
                    },
                  ]}
                  min={0}
                  max={5}
                  value={rating}
                  onChange={(e, val) => setRating(val)}
                  valueLabelDisplay="auto"
                />
              </Grid>
              <Grid item xs={12} md={6} lg={2} className={`${styles.part4}`}>
                <p>{t.get(translations, 'bonus-type')}</p>
                <FormControl component="fieldset">
                  <RadioGroup>
                  <FormControlLabel
                    checked={bonusAmount === "100"}
                    onChange={(event) => setBonusAmount(event.target.value)}
                    control={
                      <Radio
                        icon={<RadioButtonUncheckedIcon />}
                        checkedIcon={<RadioButtonCheckedIcon />}
                        value="100"
                        color="primary"
                      />
                    }
                    label={labelWithNumber('100%', filteredBonuses.filter(x => HasOneHundredBonuses(x)).length)}
                    className={`gamble-radio`}
                  />
                  <FormControlLabel
                    checked={bonusAmount === "200"}
                    onChange={(event) => setBonusAmount(event.target.value)}
                    control={
                      <Radio
                        icon={<RadioButtonUncheckedIcon />}
                        checkedIcon={<RadioButtonCheckedIcon />}
                        value="200"
                        color="primary"
                      />
                    }
                    label={labelWithNumber('200%', filteredBonuses.filter(x => HasTwoHundredBonuses(x)).length)}
                    className={`gamble-radio`}
                  />
                  <FormControlLabel
                    checked={bonusAmount === "max"}
                    onChange={(event) => setBonusAmount(event.target.value)}
                    control={
                      <Radio
                        icon={<RadioButtonUncheckedIcon />}
                        checkedIcon={<RadioButtonCheckedIcon />}
                        value="max"
                        color="primary"
                      />
                    }
                    label={labelWithNumber('200% +', filteredBonuses.filter(x => HasOverTwoHundredBonuses(x)).length)}
                    className={`gamble-radio`}
                  />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <div className={`${styles.mainCardsFiltersDialogFloatingButtons}`}>
                <Button
                  variant="contained"
                  color="secondary"
                  size="large"
                  onClick={() => clearState()}
                  className={`${styles.clearButton}`}
                >
                  {t.get(translations, 'clear-filters')}
                </Button>
                <a href="#cards-start" aria-label={t.get(translations, "search-apply-filters")} style={{ textDecoration: 'none' }}>
                  <Button variant="contained" color="primary" onClick={closeFilters} className={`${styles.filtersApplyButton}`}>
                    {t.get(translations, 'search-apply-filters')}
                  </Button>
                </a>
              </div>
            </div>
          </Dialog>
        </Grid>
      </Grid>
      <Grid item xs={12} className={`${styles.viewAll}`}>
        <Button
          variant="contained"
          color="secondary"
          size="large"
          style={{ display: showItems < bonuses.length ? "" : "none"}}
          onClick={() => setShowItems(showItems + 10)}
        >
          {t.get(translations, "show-more")}
        </Button>
      </Grid>
    </Grid>
  )
}

export default CardsSearch
