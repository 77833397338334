import React, { useContext } from "react"
import Layout from "../../components/layout"
import NullIf from "../../services/NullIf"

import ThemeContext from "../../context/ThemeContext"
import { graphql } from "gatsby"
import GenericLandingContentWrapper from "../../components/text-helpers/generic-landing-content-wrapper"
import SEO from "../../components/seo"
import CardsSearch from "../../components/cards-search/cards-search"
import CasinoHelper from "../../services/casino-bonus-helpers"
import localize from "../../components/localize"
import MainSearch from "../../components/main-search/main-search"

const FdbHasCode = (casino) => {
  const fdb = CasinoHelper.GetFirstDepositBonuses(casino)
    .find(x => x.bonusCode && x.bonusCode.length > 2);
  if (fdb)
    return fdb.bonusCode && fdb.bonusCode.length > 2;

  return false;
}

const Content = ({ bonuses, locale }) => {
  bonuses = bonuses
    .filter(x => NullIf.isNotNullOrEmpty(x))
    .filter(x => NullIf.isNotNullOrEmpty(x.bonuses))
    .filter(x => FdbHasCode(x))
  return (
    <div>
      <MainSearch bonuses={bonuses} showBonusCode={true} locale={locale} />
    </div>
  )
}

const BonusCodeLanding = ({ location, data, pageContext }) => {
  let site = useContext(ThemeContext)

  let { locale, alternateLocaleSlugs } = pageContext
  let bonuses = site.bonuses

  const pageData = data.sanityGenericLandingPage
  return (
    <Layout breadcrumbNameOverride={pageData.breadcrumbName} silo={data.sanitySilo} pageContext={pageContext} location={location}>
      <SEO location={location} alternateSlug={alternateLocaleSlugs}
        title={pageData && pageData.seoTitle}
        description={pageData && pageData.seoMeta}
      />
      <GenericLandingContentWrapper pageData={pageData} headerClassName={`gambleGeneralWrapper`} bodyClassName={`gambleGeneralWrapper`}>
        <Content bonuses={bonuses} locale={locale} />
      </GenericLandingContentWrapper>
    </Layout>
  )
}

export default localize(BonusCodeLanding)
export const pageQuery = graphql`
  query BonusCodeLnaidng {
    sanitySilo (_id: {eq: "3aacbadb-2f40-40ba-bcb8-f6deeb25edb4"}){
        _id
        breadcrumbTrail {
            ...LocaleSiloTrails
        }
    }
    sanityGenericLandingPage(
      name: { eq: "bonus-code" }
    ) {
      ...GenericLanding
    }
  }
`
